import axiosInstance from './axiosConfig';
import {Certificate} from "../../pages/companyInfoPage";

export interface ContactPageProps {
    address: string;
    phoneNumber: string;
    contactEmail: string;
    background_page: string;
    management: ManagementItemsProps[];
}

export interface ManagementItemsProps {
    fio: string;
    position: string;
    about: string;
    image_url: string;
}

export interface CompanyInfoProps {
    address: string;
    contactEmail: string;
    image: string;
    mapUrl: string;
    name: string;
    full_name: string;
    inn: string;
    kpp: string;
    ogrn: string;
    legal_address: string;
    post_address: string;
    phoneNumber: string;
    title: string;
    description: string;
    short_description: string;
    certificates: Certificate[];
    background_page: string;
    background: {
        desktop: string;
        mobile: string;
    };
    text: string;
}

export interface NewsItem {
    id?: string;
    date: string;
    topic: string;
    short: string;
    summary?: string;
    picture: string;
    thumbnail: string;
}

export interface CarouselItem {
    id?: string;
    description?: string;
    image_url: string;
    order_num?: number;
    is_active?: boolean;
}

export interface CoolGridItem {
    id?: string;
    description?: string;
    image_url: string;
    order_num?: number;
    is_active?: boolean;
}

export interface GeographyItem {
    id?: string;
    description?: string;
    image_url: string;
}

export interface VacancyItemsProps {
    date: Date;
    title: string;
    method: string;
    cost: string;
    description: string;
    background_page: string;
}

// Метод для получения изображений карусели
export const fetchCarouselImages = async (): Promise<CarouselItem[]> => {
    const response = await axiosInstance.get('/api/carousel/');
    return response.data;
};

export const fetchClientCarouselImages = async (): Promise<CarouselItem[]> => {
    const response = await axiosInstance.get('/api/client-carousel/');
    return response.data;
};

export const fetchCoolGridItems = async (): Promise<CoolGridItem[]> => {
    const response = await axiosInstance.get('/api/cool-grid/');
    return response.data;
};

export const fetchServiceGridItems = async (): Promise<CarouselItem[]> => {
    const response = await axiosInstance.get('/api/service-grid/');
    return response.data;
};

export const fetchServiceGridItemById = async (id: string): Promise<CarouselItem> => {
    const response = await axiosInstance.get(`/api/service-grid/${id}/`);
    return response.data;
};

export const fetchGeographyItem = async (): Promise<GeographyItem> => {
    const response = await axiosInstance.get('/api/geography/');
    return response.data[0];
};

// Метод для получения информации о компании
export const fetchCompanyInfo = async (): Promise<CompanyInfoProps> => {
    const response = await axiosInstance.get('/api/company-info/');
    return response.data[0];
};

// Метод для получения вакансий
export const fetchVacancyItem = async (): Promise<VacancyItemsProps[]> => {
    const response = await axiosInstance.get('/api/vacancy/');
    return response.data;
};

// Метод для получения персонала
export const fetchManagementItem = async (): Promise<ManagementItemsProps[]> => {
    const response = await axiosInstance.get('/api/management/');
    return response.data;
};
