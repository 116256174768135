import React, {useEffect, useState, useRef} from "react";
import {useAuth} from "../utils/wrapper/authContext";
import {useNavigate, useLocation} from "react-router-dom";
import styled from "styled-components";
import NavList from "../molecules/header/navList.header";
import {FaBars} from 'react-icons/fa';
import config from "../utils/config";

// Стили для контейнера заголовка
const HeaderContainer = styled.div<{ $isHomePage: boolean; $isSticky: boolean }>`
    width: 100%;
    background-size: cover;
    background-position: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: ${({$isHomePage}) => $isHomePage ? '#eb7515' : '#eb7515'};
    padding: ${({$isHomePage}) => $isHomePage ? '1rem 0' : '0.5rem 0'};
    transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

// Стили для содержимого заголовка
const HeaderContent = styled.header<{ $isHomePage: boolean; $isSticky: boolean }>`
    width: ${({$isHomePage}) => $isHomePage ? '100%' : '95%'};
    max-width: 1200px;
    height: ${({$isHomePage, $isSticky}) => $isHomePage ? ($isSticky ? '50px' : '60px') : '50px'};
    display: flex;
    align-items: center;
    justify-content: space-between; // Расположение логотипа и иконки бургера
    padding: 0 20px;
    box-sizing: border-box;
    color: #fff;
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
    margin: auto;
    position: relative;
`;

// Стили для навигационного блока
const Nav = styled.nav`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between; // Расположение элементов по краям
    gap: 1rem;
`;

// Стили для левой части навигации
const NavLeft = styled.div`
    display: flex;
    align-items: center;
    flex: 1;

    h1 {
        margin: 0;
        padding: 0;
        font-size: 2rem;
        color: #ece7e0;
    }

    h3 {
        margin: 0;
        padding: 0;
        font-size: 1.46rem;
        font-weight: bold;
        color: #2b2929;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 1.1rem;
        }
    }

    @media (max-width: 480px) {
        h1 {
            font-size: 1.2rem;
        }

        h3 {
            font-size: 0.88rem;
        }
    }
`;

// Стили для меню на весь экран
const FullScreenMenu = styled.div<{ $isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: ${({$isOpen}) => $isOpen ? 'translateY(0)' : 'translateY(-100%)'};
    opacity: ${({$isOpen}) => $isOpen ? '1' : '0'};
    transition: transform 0.5s ease, opacity 0.5s ease;
`;

// Стили для элемента меню на весь экран
const FullScreenMenuItem = styled.a`
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: #e77a22;
    }

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }

    @media (max-width: 480px) {
        font-size: 1.2rem;
    }
`;

// Стили для иконки бургера
const BurgerIcon = styled(FaBars)`
    font-size: 24px;
    cursor: pointer;
    color: #333;

    @media (min-width: 1000px) {
        display: none;
    }

    @media (max-width: 480px) {
        font-size: 20px;
    }
`;

// Стили для логотипа
const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LogoImg = styled.img`
    width: 57px;
    height: auto;
    filter: brightness(0.92);
    padding-bottom: 10px;

    @media (max-width: 768px) {
        width: 45px;
        padding-bottom: 5px;
    }
    @media (max-width: 480px) {
        width: 40px;
        padding-bottom: 10px;
    }

`;
const Header: React.FC = () => {
    const {logout, fetchUser} = useAuth();
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const [isSticky, setSticky] = useState<boolean>(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleScroll = () => {
        if (headerRef.current) {
            const offset = headerRef.current.getBoundingClientRect().top;
            setSticky(window.scrollY > offset);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Определяем, является ли текущая страница домашней
    const isHomePage = location.pathname === '/' || location.pathname === '/home';

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleLogout = () => {
        logout(navigate);
    };

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    const handleNavigation = (path: string) => {
        navigate(path);
        closeMenu();
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <HeaderContainer $isHomePage={isHomePage} $isSticky={isSticky} ref={headerRef}>
            <HeaderContent $isHomePage={isHomePage} $isSticky={isSticky}>
                <Nav>
                    <LogoContainer>
                        <LogoImg
                            src={`${config.logoUrl}/black_logo.svg`}
                            alt="Logo"
                        />
                    </LogoContainer>
                    <NavLeft>
                        <div>
                            <h3>строительная компания</h3>
                            <h1>ТЕХПРОМСЕРВИС</h1>
                        </div>
                    </NavLeft>
                    <NavList isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
                    <BurgerIcon onClick={toggleMenu}/>
                </Nav>
            </HeaderContent>

            <FullScreenMenu $isOpen={isMenuOpen}>
                <FullScreenMenuItem onClick={() => handleNavigation('/home')}>Главная</FullScreenMenuItem>
                <FullScreenMenuItem onClick={() => handleNavigation('/vacancie')}>Вакансии</FullScreenMenuItem>
                <FullScreenMenuItem onClick={() => handleNavigation('/about')}>О компании</FullScreenMenuItem>
                <FullScreenMenuItem onClick={() => handleNavigation('/contact')}>Контакты</FullScreenMenuItem>
            </FullScreenMenu>
        </HeaderContainer>
    );
};

export default Header;
