import React from 'react';
import {useForm} from 'react-hook-form';
import FormField from '../molecules/login/formField.login';
import '../styles/login.css'
import ButtonLogin from "../atoms/login/button.login";

interface LoginFormInputs {
    username: string;
    password: string;
}

interface LoginFormProps {
    onSubmit: (data: LoginFormInputs) => void;
    errors: any;
}

const LoginForm: React.FC<LoginFormProps> = ({onSubmit, errors}) => {
    const {register, handleSubmit} = useForm<LoginFormInputs>();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <FormField
                id="username"
                label="Имя пользователя"
                type="text"
                placeholder="Имя пользователя"
                register={register}
                error={errors.username?.message}
                value={'bertram_g'}
            />
            <FormField
                id="password"
                label="Пароль"
                type="password"
                placeholder="Пароль"
                register={register}
                error={errors.password?.message}
                value={'qwe!@#QWE'}
            />
            <ButtonLogin text='Войти'/>
        </form>
    );
};

export default LoginForm;
